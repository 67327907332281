import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Constant } from '../../blog/constant';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { EventManagerService } from '../../blog/event-manager.service';
import { SharedCommonModule } from '../../../../common/shared.module';
import { HeaderHomePage } from '../../../../common/components/header-home-page/header-home-page';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'robof-smart-portfolio',
  standalone: true,
  imports: [HeaderHomePage],
  templateUrl: './robof-smart-portfolio.html',
})
export class RobofSmartPortfolioMarketing implements OnInit, OnDestroy {
  parnters: any[] = [];
  public Constant = Constant;
  isScrolled: boolean = false;
  isDevice: boolean = false;
  constructor(
    public meta: Meta, public title: Title, 
    private eventManager: EventManagerService) {}

  ngOnInit() {
    this.setSEOMeta();
    if (typeof window !== 'undefined') {
      $('body').addClass('no-scroll');
    }
  }
  setSEOMeta() {
    this.title.setTitle('Fmarket - RoboF - Danh mục đầu tư thông minh');
    this.meta.updateTag({ property: 'og:title', content: 'Fmarket - RoboF - Danh mục đầu tư thông minh' });
    this.meta.updateTag({
      property: 'og:description',
      content: `RoboF - Danh mục đầu tư thông minh đầu tiên trên thị trường Quỹ mở`
    });
    this.meta.updateTag({
      property: 'og:image',
      content: environment.domain + 'assets/params/images/markerting/thumbnail-robof-smart-portfolio.png'
    });
    this.meta.updateTag({
      name: 'keywords',
      content: `Fmarket, RoboF - Danh mục đầu tư thông minh`
    });
    this.meta.updateTag({ property: 'og:url', content: environment.domain + Constant.path_markerting_robof_smart_portfolio });
  }
  ngOnDestroy(): void {
    if (typeof window !== 'undefined') {
      $('body').removeClass('no-scroll');
    }
  }
}
