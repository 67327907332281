<div id="maintenance">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-4">
        <div style="text-align: center;">
          <a href="/home"><img src="https://fma.dev.techland.link/trade/assets/images/logo/fmarket-blue.png" alt="fmarket" width="157" height="32"></a>
        </div>
      </div>
      <div class="col-12" style="margin-top: 100px;">
        <div style="text-align: center;">
          <img src="./assets/params/images/background/server-down.webp" alt="server down" width="391" height="280">
        </div>
      </div>
      <div class="col-12 mt-4 d-flex flex-column align-items-center">
        <span class="description">Website fmarket tạm thời đang được nâng cấp</span>
        <span class="comback">Chúng tôi sẽ trở lại sớm nhất!!!</span>
      </div>
    </div>
  </div>
</div>