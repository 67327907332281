// Imports
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { Constant } from '../../general/blog/constant';

// Component Decorator
@Component({
  selector: 'notFound',
  standalone: true,
  templateUrl: './notFound.html',
  styleUrls: ['./notFound.scss']
})

// Component Class
export class NotFoundComponent implements OnInit {
  constructor(
    public meta: Meta,
    public title: Title,
  ) {

  }
  ngOnInit(): void {
    this.setSEOMeta();
  }
  setSEOMeta() {
    this.title.setTitle('Không tìm thấy trang - Fmarket');
    this.meta.updateTag({ property: 'og:title', content: 'Không tìm thấy trang - Fmarket ' });
    this.meta.updateTag({
      property: 'og:description',
      content: 'Không tìm thấy trang - Fmarket ',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: Constant.url_logo_fmarket_share,
    });
    this.meta.updateTag({
      name: 'keywords',
      content: `Không tìm thấy trang, Maintaince, Fmarket `,
    });
    this.meta.updateTag({ property: 'og:url', content: environment.domain + Constant.path_404 });
  }
}