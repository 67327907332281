import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { Constant } from '../../general/blog/constant';

@Component({
  selector: 'reload-page',
  standalone: true,
  templateUrl: './reload-page.component.html',
})
export class ReloadPageComponent implements OnInit {
  constructor(
  ) {}
  ngOnInit() {
    if (typeof window !== 'undefined') {
      const url = Constant.url_home + '?reload=true';
      window.open(url, '_self');
    }
  }
   
}
