import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Constant } from "../../general/blog/constant";
import { EventManagerService } from "../../general/blog/event-manager.service";
import { environment } from "../../../../environments/environment";
import { CommonModule } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "introduce-agency",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./introduce-agency.component.html",
  styleUrls: ['./introduce-agency.component.scss'],
})

export class IntroduceAgencyComponent implements OnInit {
  Constant = Constant;
  isLoad: boolean = false;
  agencyPartnerCode: string = '';
  isMobile: any = false;
  linkRegister: string = '';
  constructor(
    public meta: Meta,
    public title: Title,
    public eventManagerService: EventManagerService,
    private route: ActivatedRoute,
  ) {
  }
  ngOnInit() {
    const params: any = this.route.snapshot.params;
    this.agencyPartnerCode = params.code;
    this.linkRegister = Constant.url_register_investor_portal  + '?agencyPartnerCode=' + this.agencyPartnerCode;
    localStorage.setItem('agencyPartnerCode', this.agencyPartnerCode);
    localStorage.setItem('partnerId', '');
    localStorage.setItem('referralCode', '');
    localStorage.setItem('thirdAppId', '');
    this.register();
    this.setSEOMeta();
  }
  register() {
    const url = Constant.url_register_investor;
    window.open(url, '_self');
  }
  setSEOMeta() {
    this.title.setTitle('Giới thiệu nhận thưởng - Agency Fmarket');
    this.meta.updateTag({ property: 'og:title', content: 'Giới thiệu nhận thưởng - Agency Fmarket' });
    this.meta.updateTag({
      property: 'og:description',
      content: 'Giới thiệu nhận thưởng - Agency Fmarket',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: environment.domain + 'trade/assets/images/background/reward_image.png',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: `Giới thiệu, nhận thưởng, Agency, Fmarket `,
    });
    this.meta.updateTag({ property: 'og:url', content: environment.domain + Constant.path_agency + '/' + this.agencyPartnerCode });
  }
}
