<div class="notFound">
  <div class="container">
    <div class="row">
        <div class="col-12">
            <div class="notFound-img" style="text-align: center">
                <img loading="lazy" src="./assets/params/images/background/404.webp" alt="404 not found">
              </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-column align-items-center">
            <p style="font-size: 2rem; color:#034581;">Không tìm thấy trang bạn yêu cầu</p>
            <a class="btn btn--home" href="/home">
              Về trang chủ
            </a>
          </div>
        </div>
    </div>
  </div>
</div>