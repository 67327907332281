import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Constant } from '../../blog/constant';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { EventManagerService } from '../../blog/event-manager.service';
import { HeaderHomePage } from '../../../../common/components/header-home-page/header-home-page';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'referral-program',
  standalone: true,
  imports: [HeaderHomePage],
  templateUrl: './referral-program.html',
})
export class ReferralProgramMarketing implements OnInit, OnDestroy {
  parnters: any[] = [];
  public Constant = Constant;
  isScrolled: boolean = false;
  isDevice: boolean = false;
  constructor(
    public meta: Meta, public title: Title, 
    private eventManager: EventManagerService) {}

  ngOnInit() {
    this.setSEOMeta();
    if (typeof window !== 'undefined') {
      $('body').addClass('no-scroll');
    }
  }
  setSEOMeta() {
    this.title.setTitle('Fmarket - Chương trình giới thiệu');
    this.meta.updateTag({ property: 'og:title', content: 'Fmarket - Chương trình giới thiệu' });
    this.meta.updateTag({
      property: 'og:description',
      content: `Giới thiệu Fmarket. Mời thêm bạn, thưởng vô hạn`
    });
    this.meta.updateTag({
      property: 'og:image',
      content: environment.domain + 'assets/params/images/markerting/thumbnail-mgt.png'
    });
    this.meta.updateTag({
      name: 'keywords',
      content: `Fmarket, Chương trình giới thiệu`
    });
    this.meta.updateTag({ property: 'og:url', content: environment.domain + Constant.path_markerting_referral_program });
  }
  ngOnDestroy(): void {
    if (typeof window !== 'undefined') {
      $('body').removeClass('no-scroll');
    }
  }
}
