import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';

import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { EventManagerService } from './pages/general/blog/event-manager.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedCommonModule } from './common/shared.module';



declare let $: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterOutlet, HttpClientModule, SharedCommonModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  categories: any[] = [];
  constructor(
    private eventManager: EventManagerService,
    public route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,

  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    }
    this.route.queryParams.subscribe((params: any) => {
      if (params.utm_source === 'accesstrade' && params.aff_sid) {
        localStorage.setItem('utm_source', params.utm_source);
        localStorage.setItem('aff_sid', params.aff_sid);
      }
      if (params['thirdAppId']) {
        localStorage.setItem('thirdAppId', params['thirdAppId']);
        localStorage.setItem('referralCode', '');
        localStorage.setItem('agencyPartnerCode', '');
        localStorage.setItem('partnerId', '');
      }
    });
  }
  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      $('body').on('click', (e: any) => {
        this.eventManager.broadcast({
          name: 'body-click',
          content: {
            event: e
          }
        });
      });
    }
  }
}
