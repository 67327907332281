import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { Constant } from '../../general/blog/constant';
import { Meta, Title } from '@angular/platform-browser';
import { FooterComponent } from '../../../common/components/footer/footer.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'download-app',
  standalone: true,
  imports: [FooterComponent],
  templateUrl: './download-app.component.html',
})
export class DownloadAppComponent implements OnInit, AfterViewInit {
  Constant = Constant; 
  public ANROID_APP =
  "https://play.google.com/store/apps/details?id=com.fincorp.fmarket";
  public IOS_APP =
    "https://apps.apple.com/vn/app/fmarket-smart-invest/id1585628917";
  constructor(
    public meta: Meta,
    public title: Title,
  ) {}
  ngOnInit() {
    this.setSEOMeta();
  }
  ngAfterViewInit(): void {
    if (Constant.checkAndroid() || Constant.checkIOS()) {
      setTimeout(() => {
          const linkApp = Constant.checkAndroid() ? Constant.DEEPLINK_ANROID_APP : Constant.DEEPLINK_IOS_APP;
          window.location.href = linkApp;
      }, Constant.checkAndroid() ? 2000 : 5000)
      window.location.href = Constant.DEEPLINK_APP_MARKET_FUND;
    }
  }
  open() {
    if (typeof window !== 'undefined') {
      let url = this.IOS_APP;
      if (Constant.checkAndroid()) {
        url = this.ANROID_APP;
      }
      window.open(url);
    }
  }
  setSEOMeta() {
    this.title.setTitle('Fmarket - Nền tảng giao dịch quỹ mở hàng đầu');
    this.meta.updateTag({
      name: 'keywords',
      content: 'Fmarket, Application, Android, IOS ',
    });
    this.meta.updateTag({
      name: 'description',
      content: 'Fmarket - Nền tảng giao dịch quỹ mở hàng đầu',
    });
    this.meta.updateTag({ property: 'og:title', content: 'Fmarket - Nền tảng giao dịch quỹ mở hàng đầu' });
    this.meta.updateTag({
      property: 'og:description',
      content: `Download Application Fmarket`,
    });
    this.meta.updateTag({
      property: 'og:image',
      content: Constant.url_logo_fmarket_share,
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://fma.dev.techland.link/trade/blog/lai-suat-ngan-hang',
    });
  }
}
