<div class="standalone_page">
	<div class="standalone_page__header">
		<div class="img-header">
			<a href="home"><img src="https://fma.dev.techland.link/trade/assets/images/logo/fmarket-white.svg" class="" alt="fmarket"></a>
		</div>
	</div>

	<div class="standalone_page__body robo-loginpage col-12">

		<div class="card form-login">

			<div class="card-block">
				<div class="robo-left"><img src="https://fma.dev.techland.link/trade/assets/images/robo/roboF.svg" alt="robo"></div>
				<div class="robo-right app__introduce">
					<h3 class="robo-title">RoboF - {{'Trợ thủ đắc lực của Nhà đầu tư'}}</h3>

					<div class="group_qr" *ngIf="!isMobile">
						<span class="group_qr-img">
							<img src="https://fma.dev.techland.link/trade/assets/images/homepage/qr-download.png" alt="down app">
						</span>
						<div class="group_qr-title">
							<label>Quét để tải ứng dụng</label>
							<span>iOS & Android</span>
						</div>
					</div>

					<div class="flex-btn" *ngIf="isMobile">
						<p class="mb-1 mt-2">Tải App để trải nghiệm tốt hơn
						</p>

						<div class="btn-app">
							<a [href]="Constant.IOS_APP" title="Fmarket Appstore ">
								<img src="https://fma.dev.techland.link/trade/assets/images/homepage/footer-app.svg" alt="appstore-fmarket">
							</a>
							<a [href]="Constant.ANROID_APP" title="Fmarket CH Play">
								<img src="https://fma.dev.techland.link/trade/assets/images/homepage/footer-google.svg" alt="chplay-fmarket">
							</a>
						</div>
					</div>
					<a class="mt-3" [href]="linkRegister">{{'Đăng ký'}}</a>
				</div>
			</div>
		</div>
	</div>

	<span class="standalone_page__footer">© 2018 FINCORP JSC. ALL RIGHTS RESERVED</span>
</div>