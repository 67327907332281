// Imports
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from '../../general/blog/constant';
import { CMSService } from '../../general/dashboard/service/data-cms.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
// import { HttpClientModule } from '@angular/common/http';
declare let $: any;
// Component Decorator
@Component({
  selector: 'maintenance',
  standalone: true,
  // imports: [HttpClientModule],
  templateUrl: './maintenance.html',
  styleUrls: ['./maintenance.scss']
})

// Component Class
export class MaintenanceComponent implements OnInit, AfterViewInit {
  public Constant = Constant;
  resource: any = {};
  time: any;
  constructor(
    public meta: Meta,
    public title: Title,
    // private CMSService: CMSService,
    //   public router: Router,
  ) {

  }
  ngOnInit(): void {
    this.setSEOMeta();
  }
  ngAfterViewInit() {
    this.getData();
    if (typeof window !== 'undefined') {
      $('#maintenance').on('click',  () => {
        if (this.time + 30 * 60 * 1000 > Date.now()) {
          this.getData();
        }
      });
    }
  }
  getData() {
    // this.time = Date.now();
    // this.CMSService.getCMSText([Constant.CMS_HOTLINE])
    // .subscribe((res: any) => {
    //   this.router.navigate(['home']);
    // }, (err) => {})
  }
  setSEOMeta() {
    this.title.setTitle('Bảo trì Fmarket');
    this.meta.updateTag({ property: 'og:title', content: 'Bảo trì Fmarket ' });
    this.meta.updateTag({
      property: 'og:description',
      content: 'Bảo trì Fmarket ',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: Constant.url_logo_fmarket_share,
    });
    this.meta.updateTag({
      name: 'keywords',
      content: `Bảo trì, Maintaince, Fmarket `,
    });
    this.meta.updateTag({ property: 'og:url', content: environment.domain + Constant.path_503 });
  }
}