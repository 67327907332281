<div class="w-100" style="position: absolute; top: 30%;">
    <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
            <a class="navbar-brand mb-3" [href]="Constant.url_home"
            ><img
              src="https://fma.dev.techland.link/trade/assets/images/logo/fmarket-blue.png"
              alt="fmarket"
              width="128"
              loading="lazy"
            /></a>
            <p class="text-center">
                Ứng dụng đầu tư quỹ mở hàng đầu Việt Nam,
                được Ủy ban Chứng khoán Nhà nước cấp phép ngày 02/04/2018.
            </p>
            <button class="btn btn__more" (click)="open()" style="background-color: #0368fe; color: white;">
                Open
            </button>
        </div>
    </div>
</div>
<div style="width: 100%; position: absolute; bottom: 0%;">
    <fmarket-footer></fmarket-footer>
</div>
